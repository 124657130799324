<template>
    <div class="switching-overlay">
        <div class="h-screen w-screen dF jC aC">
            <div class="dF fC jSB aC p-4 iItem single-instance-avatar" >
                <a-avatar v-if="inst.avatar" shape="circle" size="large" class="hex bg-offwhite-dark text-purple" :src="inst.avatar"></a-avatar>
				<a-avatar v-else shape="circle" size="large" class="hex bg-offwhite-dark text-purple" :class="$style.avatar">{{getInitial(inst.name)}}</a-avatar>
                <div class="instance-name f1 mt-4" >
                    <div class="p-0 m-0">
                        {{inst.name}}
                    </div>
                </div>
                <div class="mt-5">
                    <a-icon  type="loading"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {getInitial} from 'bh-mod'
export default {
    components: {
    },
    computed:{
        inst(){
            return this.$store.state.instance || {}
        }
    },
    methods:{getInitial},
    mounted(){
        let self = this
        setTimeout(() => {
			if(!self.$route.path.includes("/apps")){
				self.$router.push('/apps')
			}
        }, 1500);
    },

}
</script>
<style lang="scss" module>
  @import "./style.module.scss";
</style>
<style lang="scss">
    .switching-overlay{
        background:white;
        position: fixed;
        left:0;
        top:0;
        width:100vw;
        height:100vh;
        z-index:10;
        display:flex;
        align-content: center;
        justify-content: center;
    }
    .single-instance-avatar {
        opacity:0;
        transform:translateY(100px);
        animation:fadeUp 1s ease-out;
        animation-fill-mode: forwards;
        svg{
            width:50px; height:50px;
        }
        .st0{clip-path:url(#SVGID_2_);}
    }
    @keyframes fadeUp{
        to{
            transform:translateY(0);
            opacity:1;
        }
    }
</style>
